import type { LinksFunction, MetaFunction } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react';

import { CreditCardFormContextProvider } from './contexts/creditCardForm';
import { PaymentMethodContextProvider } from './contexts/paymentMethod';

import styles from './styles/app.css';
import fonts from './styles/fonts.css';

import logo from 'public/assets/logo_full-color.webp';

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  title: 'XGrow | Renovação de Assinatura',
  viewport: 'width=device-width,initial-scale=1',
  description: 'XGrow - Aprendizado e crescimento exponencial.',
  'og:image': logo,
  'og:title': 'XGrow | Renovação de Assinatura'
});

export const links: LinksFunction = () => [
{ rel: 'icon', href: '/favicon.ico', type: 'image/x-icon' },
{ rel: 'stylesheet', href: styles },
{ rel: 'stylesheet', href: fonts },
{ rel: 'preconnect', href: 'https://fonts.googleapis.com' },
{ rel: 'preconnect', href: 'https://fonts.gstatic.com', crossOrigin: 'use-credentials' }];


export default function App() {
  return (
    <html lang="pt-br">
      <head>
        <Meta />
        <Links />
      </head>
      <PaymentMethodContextProvider>
        <CreditCardFormContextProvider>
          <body>
            <Outlet />
            <ScrollRestoration />
            <Scripts />
            <LiveReload />
          </body>
        </CreditCardFormContextProvider>
      </PaymentMethodContextProvider>
    </html>);

}